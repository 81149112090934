/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { FormattedMessage, Link, useIntl } from "gatsby-plugin-react-intl"

import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { css } from "@emotion/react"

import Header from "./header"
import "./layout.css"
import MobileNav from "./mobileNav"

const Layout = ({ title, hideLogo, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={title || `Orpheus Music`} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1100,
          padding: `0 1.0875rem 1.45rem`,
        }}
      > 
      <div css={css`display: ${hideLogo ? 'none' : 'block'};`}>
        <Link to="/">
          <StaticImage
          src="../images/orpheus-logo-white.png"
          width={100}
          quality={95}
          placeholder={"tracedSVG"}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Orpheus Music Logo"
          css={css`
              position: absolute;
              font-size: 2.5em;
              top: 1.2em;
              left: 1.5em;
              z-index: 1;
              display: ${hideLogo ? 'none' : 'block'};
          `}
          />
        </Link>
        <h1 css={css`
          text-align: center;
          font-size: 3.5vw;
          margin-top: 1.25em;
          
          @media(max-width: 1430px) {
            margin-top: 150px;
            font-size: 2.5em;
          }
        `}>
          {title}
        </h1>
        <div css={css`
          width: 200px;
          height: 1px;
          background-color: white;
          margin: auto;
          margin-bottom: 10vh;
        `}></div>
        </div>
        <main css={css`
          a {
            color: white; 
            transition: opacity 0.25s; 
            &:hover {opacity: 0.5;}
          }
        `}>{children}</main>
        <MobileNav />
        <footer
          css={css`
            margin-top: 120px;
            a {
              color: white; 
              transition: opacity 0.25s; 
              &:hover {opacity: 0.5;}
            }
          `}
        >
          <div css={css`
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media(max-width: 680px) {
              flex-direction: column;
            }
          `}>
            <div css={css`flex: 1; line-height: 1.5;`}>
              <h2>Links</h2>
              {/* <Link to="/wettbewerb-2021"><FormattedMessage id="wettbewerb-2021" /></Link><br/> */}
              <Link to="/preistraeger"><FormattedMessage id="preistrager" /></Link><br/>
              <Link to="/archiv"><FormattedMessage id="archiv" /></Link><br/>
              <Link to="/verein"><FormattedMessage id="verein" /></Link><br/>
              <Link to="/podcasts"><FormattedMessage id="podcasts" /></Link><br/>
              <Link to="/kontakt"><FormattedMessage id="kontakt" /></Link><br/><br/>
            </div>
            <div css={css`flex: 1; line-height: 1.5;`}>
              <h2><FormattedMessage id="partner" /></h2>
              <div css={css`
                display: flex;
                flex-wrap: wrap;
                @media(max-width: 680px) {
                  flex-direction: column;
                }
                & > div {
                  margin-right: 1em;
                }
              `}>
                <div css={css`flex: 1;`}>
                  <h4><FormattedMessage id="veranstalter" /></h4>
                  <p>
                    <a href="https://swisschambermusicfestival.ch/de/" target="_blank" rel="noopener noreferrer">SCMF Adelboden</a><br/>
                    <a href="https://www.musikdorf.ch/" target="_blank" rel="noopener noreferrer">Festival Musikdorf Ernen</a><br/>
                    <a href="https://www.zunftkonzerte.ch/" target="_blank" rel="noopener noreferrer">Zunftkonzerte Zürich</a><br/>
                    <a href="https://zimmermannhaus.ch/" target="_blank" rel="noopener noreferrer">Zimmermann Haus Brugg</a><br/>
                    <a href="https://www.pianotriofest.ch/" target="_blank" rel="noopener noreferrer">Piano Trio Fest Bern</a><br/>
                    <a href="https://www.gml-kreuzlingen.ch/" target="_blank" rel="noopener noreferrer">GML Kreuzlingen</a>
                  </p>
                </div>
                <div css={css`flex: 1.2;`}>
                  <h4><FormattedMessage id="stiftungen-institutionen" /></h4>
                  <p>
                    <a href="https://www.bak.admin.ch/bak/de/home.html" target="_blank" rel="noopener noreferrer"><FormattedMessage id="bundesamt-kultur" /></a><br/>
                    <a href="https://prohelvetia.ch/de/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="pro-helvetia" /></a><br/>
                    <FormattedMessage id="blanken-schlemper" /><br/>
                    <a href="https://rhl-foundation.org/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="rhl-foundation" /></a><br/>
                    <a href="https://elisabethweberstiftung.ch/" target="_blank" rel="noopener noreferrer">Elisabeth Weber Stiftung</a><br/>
                    Franke Stiftung<br/>
                    <a href="https://www.stiftungsostenuto.ch/" target="_blank" rel="noopener noreferrer">Stiftung Sostenuto</a><br/>
                    <a href="https://fjdb.ch/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="fondation-duermueller-bol" /></a><br/>
                    <a href="https://www.ursulawirzstiftung.ch/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="ursula-wirz-stiftung" /></a><br/>
                    <a href="https://www.bwmf.ch/" target="_blank" rel="noopener noreferrer"><FormattedMessage id="buser-world-music-forum" /></a><br/>
                    <a href="https://gvb.ch/de/gvb-gruppe/engagement/kulturstiftung.html" target="_blank" rel="noopener noreferrer"><FormattedMessage id="gvb-kulturstiftung" /></a><br/>
                  </p>
                </div>
              </div>
            </div>
          </div>
          website by 
          {` `}
          <a href="https://lacreme.digital" target="_blank" rel="noopener noreferrer">lacreme.digital</a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
